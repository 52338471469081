import { UnlockStatus, LockedSurveyDetails, LockedSurveyStatus } from '@shared/models/billing.model';

import { LicenseUsage } from '@shared/models/plan.model';
/*
  /statuses/surveys/(answers|contacts|emails)/$teamKey/$surveyKey
  /statuses/licences/(answers|contacts|emails)/$teamKey

  count: number;      // current license or survey limit
  success: number;    // amount on unlocked data of all time (=available for reporting)
  failed: number;     // amount of locked data by license usage (or under licenses all locked data)
  retried: number;    // amount of locked data by survey quota usage (doesn't exist under licenses)
  processed: number;  // amount of unlocked data saved during current period
*/

export function usageStatusLicence(usage: LicenseUsage) {
  const canUnlockAny = 0 < usageRemainingLicense(usage);
  const canUnlockAll = canUnlockAny && usage.failed <= usageRemainingLicense(usage);
  const canUnlockSome = canUnlockAny && !canUnlockAll;

  return canUnlockSome
    ? UnlockStatus.CAN_UNLOCK_SOME
    : canUnlockAll
      ? UnlockStatus.CAN_UNLOCK_ALL
      : UnlockStatus.CANNOT_UNLOCK_ANY;
}

export function usageStatusSurvey(usage: LicenseUsage) {
  const canUnlockAny = 0 < usageRemainingLicense(usage);
  const canUnlockAll = canUnlockAny && usage.retried <= usageRemainingLicense(usage);
  const canUnlockSome = canUnlockAny && !canUnlockAll;

  return canUnlockSome
    ? UnlockStatus.CAN_UNLOCK_SOME
    : canUnlockAll
      ? UnlockStatus.CAN_UNLOCK_ALL
      : UnlockStatus.CANNOT_UNLOCK_ANY;
}

export function usageRemainingLicense(usage: LicenseUsage) {
  return Math.max(0, usage.count - usage.success);
}

export function usageRemainingSurvey(usage: LicenseUsage) {
  return usage.count <= usage.retried
    ? 0
    : // Licence limit - unlocked count (for current period)
      Math.max(0, usage.count - usage.processed);
}

export function usageAvailableLicense(usage: LicenseUsage) {
  return usage.count <= usage.failed ? 0 : Math.min(usageRemainingLicense(usage), usage.failed);
}

export function usageAvailableSurvey(usage: LicenseUsage) {
  return usage.count <= usage.retried ? 0 : Math.min(usageRemainingSurvey(usage), usage.retried);
}

export function usageCollected(usage: LicenseUsage) {
  return usage.success;
}

export function usageLocked(usage: LicenseUsage) {
  return usage.failed || usage.retried;
}

export function totalLocked(usage: LicenseUsage) {
  return usage.failed + usage.retried;
}

export function usageTotal(usage: LicenseUsage) {
  return usage.success;
}

export function status2Details(status: LockedSurveyStatus) {
  return {
    surveyKey: status.surveyKey,
    lockedAnswers: status.answers.licenseFull,
    reasonAnswers: 1,
    lockedContacts: status.contacts.licenseFull,
    reasonContacts: 1,
  } as LockedSurveyDetails;
}

export function statusByType(type: 'answers' | 'contacts', statuses: LockedSurveyStatus[]) {
  return type === 'answers'
    ? statuses.filter((status) => status.answers && status.answers.licenseFull !== 0)
    : statuses.filter((status) => status.contacts && status.contacts.licenseFull !== 0);
}
